import React, { useState } from 'react'
import { Tabs, Button, Modal, Input, Upload, message, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FaPlus } from 'react-icons/fa'
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

function Complaint() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState('Sample, text')

    const handleChange = (value) => {
        setData(value)
    }
    const onChange = (key) => {
        console.log(key);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const items = [
        {
            label: 'Complain',
            key: 'complain',
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-1 text-gray-500 fill-current">
                <path d="M12 1.4667969C11.477502 1.4667901 10.954623 1.6013263 10.488281 1.8730469L10.488281 1.875L2.9921875 6.2480469C2.3788628 6.6056562 2 7.2646798 2 7.9746094L2 18C2 19.093063 2.9069372 20 4 20L20 20C21.093063 20 22 19.093063 22 18L22 7.9746094C22 7.2656302 21.620948 6.6055572 21.007812 6.2480469L13.511719 1.875C13.045569 1.6029544 12.522498 1.4668036 12 1.4667969 z M 12 3.4648438C12.173826 3.464925 12.348056 3.5106081 12.503906 3.6015625L20 7.9746094L20 7.9765625L20 8.0234375L13.056641 12.34375C12.409641 12.74675 11.590359 12.74675 10.943359 12.34375L4 8.0234375L4 7.9746094L11.496094 3.6015625C11.652752 3.510283 11.826174 3.4647625 12 3.4648438 z"></path>
            </svg>,
            children: <div className='flex flex-col'>
                <Button onClick={showModal} className='flex items-center justify-center w-[150px] ml-auto border-2 border-DaDUprimary bg-DaDUprimary text-white hover:!border-DaDUprimary hover:bg-transparent hover:!text-DaDUprimary' icon={<FaPlus />}>Complain</Button>
                <div className="flex flex-col h-full items-center justify-center py-10">
                    <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
                        <i className="anticon text-4xl text-gray-600 ">
                            <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                                <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                                </path>
                            </svg>
                        </i>
                    </div>
                    <div className="mt-6 text-center">
                        <p className="text-lg font-semibold mb-0">
                            Complain
                        </p>
                        <p >
                            No Complain Data
                        </p>
                    </div>
                </div>
            </div>,
        },
        {
            label: 'Escalate',
            key: 'escalate',
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-1 text-gray-500 fill-current">
                <path d="M6 2C4.895 2 4 2.895 4 4L4 19C4 20.64497 5.3550302 22 7 22L20 22L20 20L7 20C6.4349698 20 6 19.56503 6 19C6 18.43497 6.4349698 18 7 18L20 18L20 17L20 16L20 2L16 2L16 12L13 10L10 12L10 2L6 2 z"></path>
            </svg>,
            children: <div className="flex flex-col h-full items-center justify-center py-10">
                <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
                    <i className="anticon text-4xl text-gray-600 ">
                        <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                            <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                            </path>
                        </svg>
                    </i>
                </div>
                <div className="mt-6 text-center">
                    <p className="text-lg font-semibold mb-0">
                        Escalate
                    </p>
                    <p >
                        No Escalate Data
                    </p>
                </div>
            </div>,
        },
        {
            label: 'Complain Cancellation',
            key: 'complainCancellation',
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-1 text-gray-500 fill-current">
                <path d="M6 2C4.895 2 4 2.895 4 4L4 19C4 20.64497 5.3550302 22 7 22L20 22L20 20L7 20C6.4349698 20 6 19.56503 6 19C6 18.43497 6.4349698 18 7 18L20 18L20 17L20 16L20 2L16 2L16 12L13 10L10 12L10 2L6 2 z"></path>
            </svg>,
            children: <div className="flex flex-col h-full items-center justify-center py-10">
                <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
                    <i className="anticon text-4xl text-gray-600 ">
                        <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                            <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                            </path>
                        </svg>
                    </i>
                </div>
                <div className="mt-6 text-center">
                    <p className="text-lg font-semibold mb-0">
                        Cancled Complain
                    </p>
                    <p >
                        No  Cancled Complain Data
                    </p>
                </div>
            </div>,
        },
        {
            label: 'Clarification',
            key: 'clarification',
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-1 text-gray-500 fill-current">
                <path d="M6 2C4.895 2 4 2.895 4 4L4 19C4 20.64497 5.3550302 22 7 22L20 22L20 20L7 20C6.4349698 20 6 19.56503 6 19C6 18.43497 6.4349698 18 7 18L20 18L20 17L20 16L20 2L16 2L16 12L13 10L10 12L10 2L6 2 z"></path>
            </svg>,
            children: <div className="flex flex-col h-full items-center justify-center py-10">
                <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
                    <i className="anticon text-4xl text-gray-600 ">
                        <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                            <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                            </path>
                        </svg>
                    </i>
                </div>
                <div className="mt-6 text-center">
                    <p className="text-lg font-semibold mb-0">
                        Clarification
                    </p>
                    <p >
                        No Clarification Data
                    </p>
                </div>
            </div>,
        },

    ];
    const props = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    return (
        <main className="-m-2 ">
            <div className="flex-shrink-0 ">
                <div className="flex items-center justify-between px-3 py-4 text-sm font-semibold text-gray-900 bg-white border-b">
                    <div > Clarification </div>
                </div>
                <Tabs
                    className='px-4'
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                />

            </div>
            <Modal title="Complain" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    layout="vertical"
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Content Body"
                        name="contentBody"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <ReactQuill value={data} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Add File</Button>
                        </Upload>
                    </Form.Item>
                    {/* <Form.Item>
                        <Button className='py-2 px-4 bg-DaDUprimary border-2 border-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center font-bold'>Save</Button>
                    </Form.Item> */}
                </Form>
            </Modal>
        </main>
    )
}

export default Complaint