import React from 'react'
import { Card, Input } from 'antd'

const { Search } = Input;

function AwardNegotiation() {
    return (
        <div className='flex  gap-2 w-full'>
            <div className='w-1/2'>
                <Card title="Notification Items" style={{ width: "100%" }}>
                    <Search placeholder='ENTER search here' />
                </Card>
            </div>
            <div className='w-1/2'>
                <Card title='Correspondences' style={{ width: "100%" }}>

                </Card>
            </div>
        </div>
    )
}

export default AwardNegotiation