import React, { useEffect, useState, useRef } from 'react';
import Img from "../images/values-1.png";
import Img2 from "../images/values-3.png";
import PlaceBid from './PlaceBid';
import BidHistory from './BidHistory';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, Thumbs } from "swiper";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProductDetails } from "../../actions/productAction";
import Loader from '../Loader/Loader';
import { useAlert } from 'react-alert';
import MetaData from '../MetaData/MetaData';
import StripeCheckout from "react-stripe-checkout";
import { Alert, Table } from 'antd';
import {
  MdVerified,
  MdOutlinePayment
} from 'react-icons/md';

import "./productdetails.scss";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/thumbs';
import 'swiper/css/a11y';
import 'font-awesome/css/font-awesome.min.css';
import { API_URL } from '../../config';

// Swiper Tesing
SwiperCore.use([Navigation, Pagination, Thumbs, Autoplay]);



const ProductDetails = ({ match }) => {

  const [didMount, setDidMount] = useState(false);
  const [userData, setUserData] = useState({ _id: "" });
  const [showButton, setShowButton] = useState(true);

  // FOR PAYMENT
  const [items, setItems] = useState({
    name: "Buy item",
    price: 10,
    productBy: "Seller name"
  });

  // var winStatus = "init";
  // FIRST STEP
  const dispatch = useDispatch();
  const alert = useAlert();
  // THIRD STEP -> Fetching From Store
  const { product, loading, error, sellerDetails, winStatus } = useSelector((state) => state.productDetails);
  //  winStatus  = useSelector((state) => state.productDetails);
  // SECOND STEP


  // GET USER DATA

  const userContact = async () => {

    try {
      const res = await fetch(`${API_URL}/api/getdata`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      });
      const data = await res.json();
      console.log(`id is ${data._id}`);
      // console.log(`data send to backend`);
      if (data) {
        setUserData({ _id: data._id });
      }
      if (!res.status === 200) {
        const error = new Error(res.error);
        throw error;

      }


    } catch (err) {
      console.log(err);
    }

  }


  // ENDED

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    setDidMount(true);
    // at backend -> req.params.id and at Front end -> match.params.id
    // dispatch(getProductDetails(match.params.id));
    userContact();

    return () => setDidMount(false);

  }, [dispatch, match.params.id, error, alert]);


  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const slides = []

  {
    product?.images && product.images.map((item, i) => (
      // element to push
      slides.push(
        <SwiperSlide key={`slide-${i}`} tag="li">
          <div className="single-prd-item">
            <img className="img-fluid"
              key={item.url}
              src={item.url}
              alt={`${i} Slide`} />
          </div>
        </SwiperSlide>
      )
    ))
  }


  // FOR PAYMENT

  const makePayment = token => {
    const body = {
      token,
      items
    }
    const headers = {
      "Content-Type": "application/json"
    }

    return fetch(`${API_URL}/api/payment`, {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    }).then(response => {
      console.log("RESPONSE", response)
      const { status } = response;
      console.log("STATUS", status);
      setShowButton(false);

    })
      .catch(error => console.log(error));
  }




  // for (let i = 0; i < 5; i += 1) {
  //   slides.push(
  //     <SwiperSlide key={`slide-${i}`} tag="li">
  //       <div className="single-prd-item">
  //         <img className="img-fluid" src={Img} alt="Product Image" />
  //       </div>
  //     </SwiperSlide>
  //   );

  // }

  const thumbs = []

  // for (let i = 0; i < 5; i += 1) {
  //   thumbs.push(
  //     <SwiperSlide key={`thumb-${i}`} tag="li" style={{ listStyle: 'none' }}>
  //       <div className='multiproduct-itm'>
  //         <img className='coursel-img2' src={Img2} alt="Product2 Image" />
  //       </div>
  //     </SwiperSlide>
  //   );

  // }
  {
    product?.images && product.images.map((item, i) => (

      thumbs.push(
        <SwiperSlide key={`thumb-${i}`} tag="li" style={{ listStyle: 'none' }}>
          <div className='multiproduct-itm'>
            <img className="img-fluid"
              key={item.url}
              src={item.url}
              alt={`${i} Slide`} />
          </div>
        </SwiperSlide>
      )

    ))
  }

  // const sellerInfo = product;
  // const { email, name, phone, _id } = product.seller;
  // let keys = product.keys(product)
  // console.log(keys);
  // console.log(product.seller);

  // console.log(product.seller);
  // { product.seller && product.seller.map((u) => console.log(u.name)) }
  // console.log()

  // For Converting Into Time Format
  const year = new Date(product.bidEnd).getFullYear();
  const month = new Date(product.bidEnd).getMonth();
  const date = new Date(product.bidEnd).getDate();
  const hours = new Date(product.bidEnd).getHours();
  const minutes = new Date(product.bidEnd).getMinutes();

  console.log(`${date}-${month}-${year}  ${hours}:${minutes}`);


  const created_year = new Date(product.createdAt).getFullYear();
  const created_month = new Date(product.createdAt).getMonth();
  const created_date = new Date(product.createdAt).getDate();
  const created_hours = new Date(product.createdAt).getHours();
  const created_minutes = new Date(product.createdAt).getMinutes();
  // Completed


  // Claculate Reaining Time




  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date(product.bidEnd).getTime();

    interval = setInterval(() => {
      // GET A NEW CURRENT DATE AT EVERY SECOND
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // STOP TIMER
        clearInterval(interval.current);
      }
      else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);

      }

    }, 1000);
  };

  // COMPONENT DID MOUNT LIFECYCLE

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };


  });



  // console.log(winStatus.email);


  if (!didMount) {
    return null;
  }

  var countdownDate = new Date(product.bidEnd).getTime();
  var now = new Date().getTime();

  // END DATE
  var dateEnd = new Date(product.bidEnd);

  // Winner Announce --> Working
  // console.log(winStatus);
  // { `: ${dateEnd.getDate()}-${dateEnd.getMonth()}-${dateEnd.getFullYear()}       ${dateEnd.getHours()}:${dateEnd.getMinutes()}  ` }


  // console.log(sellerDetails.name);
  // console.log(product.bidEnd)


  if (countdownDate > now) {
    console.log(`Auction Runnning`);
  } else {

    console.log(`Auction Ended`);
  }

  // REFRESH WHEN AUCTION ENDED
  var refreshTime = (countdownDate - now);
  if (refreshTime > 1) {
    setTimeout(function () { window.location.reload(true); }, refreshTime);
  }


  console.log(showButton);
  // console.log(sellerDetails.name);

  const handleRegisterClick = () => {
    // Handle the click event (navigate to registration page or perform other actions)
    console.log('Clicked on "Click here to register"');
  };

  const classificationData = [
    {
      key: '1',
      code: 200102102,
      title: "other service",

    }
  ];

  const classificationColumn = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    }
  ]

  const documentData = [
    {
      key: '1',
      factor: "Nationality",
      criteria: "Nationality in accordance with ITB Clause 4.2.",

    },
    {
      key: '2',
      factor: 'Conflict of Interest',
      criteria: "No conflict of interest as described in ITB Clause 6.",

    },
    {
      key: '3',
      factor: "Valid trade license or business organization registration certificate",
      criteria: "Having been submitted valid trade license or business organization registration certificate issued by the country of establishment in accordance with ITB Clause 4.6",

    }
  ];

  const documentColumn = [
    {
      title: 'Factor',
      dataIndex: 'factor',
      key: 'factor',
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
    }
  ]
  return (
    <>{loading ? (<Loader />) : (
      <>
        <MetaData title={`Product Tender`}></MetaData>
        <div className='w-full h-auto bg-DaDUprimary bg-opacity-10 flex p-3 gap-3'>
          <div className='w-1/4 h-full bg-white rounded-xl lg:block hidden'>
            <h3 className='py-2 px-4 mb-0 mt-2 font-bold text-[24px]'>Related Bid</h3>
            <hr className='mt-2' />
            <div className='w-full h-auto flex flex-col items-center'>

              <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg" class="h-full m-auto"><g fill="none" fill-rule="evenodd"><g transform="translate(24 31.67)"><ellipse cx="67.797" cy="106.89" rx="67.797" ry="12.668" class="ant-empty-img-default-ellipse"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" class="ant-empty-img-default-path-1"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" transform="translate(13.56)" class="ant-empty-img-default-path-2"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" class="ant-empty-img-default-path-3"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" class="ant-empty-img-default-path-4"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" class="ant-empty-img-default-path-5"></path><g transform="translate(149.65 15.383)" class="ant-empty-img-default-g"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
              <p>No Related Lots Avaliable</p>
            </div>
          </div>
          <div className='lg:w-3/4 w-full h-full bg-white rounded-xl'>
            <h3 className='py-2 px-4 mb-0 mt-2 font-bold text-[24px]'>Lot72: - Procurement of Hotel Services</h3>
            <hr className='mt-2' />
            <div className='px-2'>
              <div className='py-2 '>
                <Alert
                  message={<p className='mb-0'>The time zone is: <span className='font-bold'>East Africa Time (GMT+3:00)</span></p>}
                  type="info"
                />
              </div>
              <div className='w-full h-auto pt-2 px-3 mt-3 mb-3 bg-white border border-gray-400'>
                <div className="flex justify-between">
                  <p className="font-semibold text-gray-800 text-base">Package Information</p>
                </div>
                <div className='pt-2 -mx-4 text-gray-600 overflow-x-scroll'>
                  <div className='border-t border-b'>
                    <table className="w-full mb-0 ">
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Invitation Date</td>
                        <td className="p-3">Jan 26, 2024</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Procurement Reference No</td>
                        <td className="p-3">ASTU-NCB-NC-0052-2016-BID</td>
                      </tr><tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Procurement Category</td>
                        <td className="p-3">NonConsultancyServices</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Market Type</td>
                        <td className="p-3">National</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Procurement Method</td>
                        <td className="p-3">Open</td></tr><tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Procurement ClassNameification</td>
                        <td >
                          <Table pagination={false} dataSource={classificationData} columns={classificationColumn} />
                        </td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Procuring Entity</td>
                        <td className="p-3">Adama Science and Technology University</td>
                      </tr>
                      <tr >
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Address</td>
                        <td className="p-3 flex justify-between space-x-2 ng-star-inserted">
                          <div className="border-l-2 border-r-2 px-2 w-full">
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Country:</span>
                              <span >Ethiopia</span>
                            </p>
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Town:</span>
                              <span >Adama</span>
                            </p>
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Street:</span>
                              <span >Adama</span>
                            </p></div>
                          <div className="border-l-2 border-r-2 px-2">
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Room Number:</span>
                              <span >106</span>
                            </p>
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Telephone:</span>
                              <span >+251221100090</span>
                            </p>
                          </div>
                          <div className="border-l-2 border-r-2 px-2">
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Email:</span>
                              <span >kumawaktola@gmail.com</span>
                            </p>
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Po Box:</span>
                              <span >
                              </span>
                            </p>
                            <p className="p-2 flex space-x-2 ng-star-inserted">
                              <span className="font-semibold">Fax:</span>
                              <span >-</span>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="px-3 pt-3 mb-3 bg-white border border-gray-400">
                <div className="flex justify-between">
                  <p className="font-semibold text-gray-800 text-base">Lot Information</p>
                </div>
                <div className="pt-2 -mx-3 text-gray-600">
                  <div className="border-t border-b">
                    <table className="w-full mb-0 ng-star-inserted">
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Object of Procurement</td>
                        <td className="p-3">Lot72: - Procurement of Hotel Services</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Description</td>
                        <td className="p-3 ng-star-inserted">Lot72: - Procurement of Hotel Services</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Lot Number</td>
                        <td className="p-3">1</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r"> Clarification Request Deadline </td>
                        <td className="p-3">Feb 5, 2024, 7:30:00 PM</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Pre-Bid Conference Schedule</td>
                        <td className="p-3 ng-star-inserted">Not Applicable</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Site Visit Schedule</td>
                        <td className="p-3 ng-star-inserted">Not Applicable</td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Bid Submission Deadline</td>
                        <td className="p-3">Feb 9, 2024, 10:00:00 PM</td>
                      </tr>
                      <tr >
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Bid Opening Schedule</td>
                        <td className="p-3">Feb 9, 2024, 10:30:00 PM</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="px-3 pt-3 mb-3 bg-white border border-gray-400">
                <div className="flex justify-between">
                  <p className="font-semibold text-gray-800 text-base">Source of Fund
                  </p>
                </div><div className="pt-2 -mx-3 text-gray-600">
                  <div className="border-t border-b">
                    <table className="w-full mb-0 ng-star-inserted">
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Funding Source
                        </td>
                        <td className="p-3">
                        </td>
                      </tr>
                      <tr ><td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Governing Laws
                      </td>
                        <td className="p-3">The Federal Republic of Ethiopia
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="px-3 pt-3 mb-3 bg-white border border-gray-400">
                <div className="flex justify-between">
                  <p className="font-semibold text-gray-800 text-base">Eligibility Requirements
                  </p>
                </div>
                <div className="pt-2 -mx-3 text-gray-600">
                  <div className="border-t border-b">
                    <table className="w-full mb-0 ng-star-inserted">
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Participation Fee
                        </td>
                        <td className="p-3 ng-star-inserted"> Free of Charge
                        </td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Eligibility Documents
                        </td>
                        <td >
                          <Table pagination={false} dataSource={documentData} columns={documentColumn} />
                        </td>
                      </tr>
                      <tr className="border border-t-0 border-l-0 border-r-0 border-dashed">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-r">Bid Security Amount
                        </td>
                        <td className="p-3 ng-star-inserted"> 30,000
                        </td>
                      </tr>
                      <tr className="ng-star-inserted">
                        <td className="w-1/4 p-3 font-semibold bg-gray-100 border-b border-r"> Bid Security Form For Foreign Bidders
                        </td>
                        <td className="p-3"> Bank Guarantee,
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="px-3 pt-3 mb-3 bg-white border border-gray-400">
                <div class="flex justify-between">
                  <p class="font-semibold text-gray-800 text-base">Notice
                  </p>
                </div>
                <div class="pt-2 -mx-3 text-gray-600">
                  <div class="border-t border-b">
                    <table class="w-full mb-0 ng-star-inserted">
                      <tr
                      ><td class="w-1/4 p-3 font-semibold bg-gray-100 border-r">Terms and Conditions
                        </td>
                        <td class="p-3">The institution has the right to cancel BID partially or fully. The bidder must submit   bid security physically before opening date.
                          The bidders should attach the star certificate given federal culture and truism minister.
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className='pb-2 '>
                {/* <Alert
                  message={<p className='mb-0'>Please complete your supplier registration in order to register on available tenders. <a className='font-bold text-black no-underline hover:underline' onClick={handleRegisterClick}>Click here to register</a></p>}
                  type="info"
                /> */}
                <div class="flex justify-end px-6 py-4 mt-2 mb-6 text-gray-600 bg-gray-200 border border-gray-400 ">
                  <button class="mr-3 py-2 px-3 bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary shadow border-2 border-DaDUprimary rounded">
                    <span class=""> Bookmark </span>
                  </button>
                  <button
                    class="py-2 px-3 bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary shadow border-2 border-DaDUprimary rounded ">
                    <span class=""> Register </span></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='product_d'>
          {/* <div className="product_image_area">
            <div className="container">
              <div className="row s_product_inner">
               <div className="col-lg-6">
                  <div className="s_Product_carousel">
                    <div className="single-prd-item">
                      <img className="img-fluid" src={Img} alt="Product Image" />
                    </div>
                    <div className="single-prd-item">
                      <img className="img-fluid" src={Img} alt="Product Image" />
                    </div>
                    <div className="single-prd-item">
                      <img className="img-fluid" src={Img} alt="Product Image" />
                    </div>
                    <div className='coursel-manage'>
                      <Swiper tag="section" wrapperTag="ul"
                        modules={[Navigation, Pagination, A11y, Autoplay]}
                        id="main"
                        thumbs={{ swiper: thumbsSwiper }}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        autoplay
                        spaceBetween={0}

                      >{slides}</Swiper>

                    </div>

                    <Swiper id="thumbs"
                      spaceBetween={0}
                      slidesPerView={3}
                      onSwiper={setThumbsSwiper}
                    >
                      {thumbs}
                    </Swiper>

                  </div>
                </div> 
                 <div className="col-lg-5 offset-lg-1">
                  <div className="s_product_text">
                    <h3>{product.itemName}</h3>
                    <h2>{`₹ ${product.startingBid}`}</h2>
                    <ul className="list">
                      <li><a className="active" href="#"><span>Category</span> : {product.category}</a></li>

                      {(countdownDate > now) ? (
                        <li><a href="#"><span>Ends In </span>
                          {`: ${timerDays}:${timerHours}:${timerMinutes}:${timerSeconds} `}
                        </a>
                        </li>
                      )
                        :
                        (
                          <li><a href="#"><span>Ended </span>
                            {`: ${dateEnd.getDate()}-${dateEnd.getMonth()}-${dateEnd.getFullYear()}       ${dateEnd.getHours()}:${dateEnd.getMinutes()}  `}
                          </a>
                          </li>
                        )

                      }
                    </ul>
                    <p>
                      {product.description}
                    </p>
                    {
                      (countdownDate > now && userData._id !== sellerDetails._id) ?
                        (<PlaceBid product={product} />)
                        :
                        (<h3>!   Auction Ended</h3>)
                    }
                              {
                      (countdownDate > now) ?
                        (

                          (userData._id !== sellerDetails._id) ? (<PlaceBid product={product} />)
                            :
                            (<div className="alert alert-primary" role="alert">
                              <MdVerified />  Your Product Auction is currently active!
                            </div>)

                        )
                        :
                        (<h3>!   Auction Ended</h3>)
                    }
                  </div>
                </div> 
              </div>
            </div>
          </div> */}
          {/* {userData._id === winStatus.bidder._id &&
            <div className="row">
              <div className="alert alert-primary" role="alert">
                <MdVerified />  You Won. Congratulations!
              </div>
              {showButton && (
                <div className='stripecls'>
                  <StripeCheckout
                    stripeKey="pk_test_51KVzMySFWvR6XE1YKfGlTIsCD9C6Iwr2hy4H5ZkeddvgmJwUAGOJcOvWE6FeFh2qkANlnXD6f10wdZuuBHi0CoCj00s25fgrYw"
                    token={makePayment}
                    name="Buy here!!"
                    amount={winStatus.bid}
                    shippingAddress
                    billingAddress
                  >
                    <button class="button" ><
                      MdOutlinePayment /> <span>Connect with Stripe </span></button>
                  </StripeCheckout>
                </div>
              )
              }
            </div>} */}
          {/* PRODUCT ADDITIONAL INFORMATION */}
          <div className='product_info bg-DaDUprimary bg-opacity-5 py-10'>
            <div className='row'>
              <div className='col-10 mx-auto'>
                <div className="section-title" data-aos="fade-up">
                  <h2>Additional Information</h2>
                  <p>Tender owner and other Additional Information</p>
                </div>
                {/* Section Starts */}
                <section id="features" className="features">
                  <div className="container" data-aos="fade-up">

                    {/* <ul className="nav nav-tabs row  d-flex justify-content-center">

                      <li className="nav-item col-6 col-md-4 col-lg-2">
                        <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                          <i className="bi bi-binoculars color-cyan"></i>
                          <h4>Seller</h4>
                        </a>
                      </li>
                      <li className="nav-item col-6 col-md-4 col-lg-2">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                          <i className="bi bi-box-seam color-indigo"></i>
                          <h4>Product</h4>
                        </a>
                      </li>
                      <li className="nav-item col-6 col-md-4 col-lg-2">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                          <i className="bi bi-box-seam color-indigo"></i>
                          <h4>Status</h4>
                        </a>
                      </li>
                    </ul> */}
                    <div className="mt-10 ">
                      {/* Card CSS */}
                      <div className="tab-pane active show  bg-DaDUprimary bg-opacity-10 p-10 shadow-xl" id="tab-1">
                        <div className="row ">
                          <div className="col-lg-8 order-2 order-lg-1 " data-aos="fade-up" data-aos-delay="100">
                            <div className="tab-pane fade show active profile-overview" id="profile-overview">
                              <h5 className="font-[700] text-DaDUprimary text-center mb-4">Seller Details</h5>
                              <div className="row">
                                <div className="col-lg-3 col-md-4 text-DaDUprimary ">Institute Name</div>
                                {/* <div className="col-lg-9 col-md-8">{sellerDetails.name}</div> */}
                                <div className="col-lg-9 col-md-8">DaDU Referal Hospital</div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-4 text-DaDUprimary">Sector</div>
                                <div className="col-lg-9 col-md-8">Health</div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-4 text-DaDUprimary">Phone</div>
                                {/* <div className="col-lg-9 col-md-8">{sellerDetails.phone}</div> */}
                                <div className="col-lg-9 col-md-8">+251789714664</div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-4 text-DaDUprimary">Email</div>
                                {/* <div className="col-lg-9 col-md-8">{sellerDetails.email}</div> */}
                                <div className="col-lg-9 col-md-8">daduhospital@dadu.edu.et</div>
                              </div>
                              <div className="row">
                                <div className="rounded py-2 px-3 text-DaDUprimary  bg-DaDUprimary bg-opacity-20 border-2 border-DaDUprimary flex items-center justify-between " role="alert">
                                  <MdVerified />  Seller is Verfied by DaDU Proc<div />
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="col-lg-4 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                            <img src={Img} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      {/* <div className="tab-pane card" id="tab-2">
                        <div className="row gy-4">
                          <div className="tab-pane fade show active profile-overview" id="profile-overview">
                            <h5 className="card-title">Product Details</h5>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label ">Product Name</div>
                              <div className="col-lg-9 col-md-8">{product.itemName}</div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">Description</div>
                              <div className="col-lg-9 col-md-8">{product.description}</div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">Created At</div>
                              <div className="col-lg-9 col-md-8">{`${created_date}-${created_month}-${created_year}  ${created_hours}:${created_minutes}`}</div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">Initial Price</div>
                              <div className="col-lg-9 col-md-8">{product.startingBid}</div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">End Time</div>
                              <div className="col-lg-9 col-md-8">{`${date}-${month}-${year}  ${hours}:${minutes}`}</div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="tab-pane card" id="tab-3">
                        <div className="row gy-4">
                          {
                            (countdownDate > now) ? (
                              <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                <h5 className="card-title">Auction Status</h5>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 label ">Status</div>
                                  <div className="col-lg-9 col-md-8">Auction is Currently Active</div>
                                </div>
                              </div>

                            ) : (
                              <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                <h5 className="card-title">Auction Status</h5>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 label ">Status</div>
                                  <div className="col-lg-9 col-md-8">Auction Ended</div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 label ">Auction Winner</div>
                                  <div className="col-lg-9 col-md-8">{winStatus?.bidder.name}</div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 label ">Winner Email</div>
                                  <div className="col-lg-9 col-md-8">{winStatus?.bidder.email}</div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 label ">Winner Phone</div>
                                  <div className="col-lg-9 col-md-8">{winStatus?.bidder.phone}</div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 label ">Winning Ammount</div>
                                  <div className="col-lg-9 col-md-8">{winStatus?.bid}</div>
                                </div>
                                <div className="row">
                                <div className="col-lg-3 col-md-4 label ">Winner Email</div>
                                    <div className="col-lg-9 col-md-8">{winStatus.bidder._id}</div>
                                  </div>
                                {userData._id === winStatus.bidder._id &&
                                  <div className="row">
                                    <div className="alert alert-primary" role="alert">
                                      <MdVerified />  You Won. Congratulations!
                                    </div>
                                    {showButton && (
                                      <div className='stripecls'>
                                        <StripeCheckout
                                          stripeKey="pk_test_51KVzMySFWvR6XE1YKfGlTIsCD9C6Iwr2hy4H5ZkeddvgmJwUAGOJcOvWE6FeFh2qkANlnXD6f10wdZuuBHi0CoCj00s25fgrYw"
                                          token={makePayment}
                                          name="Buy here!!"
                                          amount={winStatus.bid}
                                          shippingAddress
                                          billingAddress
                                        >
                                          <button class="button" ><
                                            MdOutlinePayment /> <span>Connect with Stripe </span></button>
                                        </StripeCheckout>
                                      </div>
                                    )
                                    }
                                  </div>}
                              </div>
                            )
                          }
                        </div>
                      </div> */}
                    </div>
                  </div>
                </section>
              </div></div>
          </div>
          {/* BID HISTORY SECTION */}
          {/* {!(countdownDate > now) &&
            <div className="container-fluid nav_bg bidhistory">
              <div className='row'>
                <div className='col-10 mx-auto'>
                  <div className="section-title" data-aos="fade-up">
                    <h2>Bid  History</h2>
                    <p>Bidding history of Auction</p>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{`Product is Bidded upto ${winStatus.bid}`}</h5>
                      {product.bids && product.bids.map(bids => (
                      <Product product = {product} />
                        ))}
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Bidder Name</th>
                            <th scope="col">Bidder Email</th>
                            <th scope="col">Bid Ammount</th>
                            <th scope="col">Bid Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {product.bids && product.bids.map(bids => (
                            <BidHistory bids={bids} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          } */}
          {/* BID HISTORY ENDED */}
        </div></>
    )}</>
  );
};

export default ProductDetails;