import React from 'react'
import { Card, Input, Table } from 'antd'
import { useHistory } from 'react-router-dom'

const { Search } = Input;


const data = [
    {
        key: '1',
        tin: '0041255271',
        entityName: 'peace security plc',
        Description: "Sample Description",
        bidType: "Purchasing",
        legalEntity: 'Private Limited Company',
        date: "86823"
    },
    {
        key: '2',
        tin: '0087033164',
        entityName: "ABIYOT TESEMA SHIFERAW",
        Description: "Sample Description",
        bidType: "Purchasing",
        legalEntity: 'Sole Proprietorship',
        date: "62712"
    },
    {
        key: '3',
        tin: '0062401805',
        entityName: 'SIFRASH WORKNEH ABITEW',
        Description: "Sample Description",
        bidType: "Tendering",
        legalEntity: 'Sole Proprietorship',
        date: "61845"
    },
    {
        key: '4',
        tin: '0067948231',
        entityName: 'GOGEB STARS ENGINEERING PLC',
        Description: "Sample Description",
        bidType: "Tendering",
        legalEntity: 'Private Limited Company',
        date: "51114"
    },
    {
        key: '4',
        tin: '0050513547',
        entityName: 'YONAS ADISSU ORDOFA',
        Description: "Sample Description",
        bidType: "Purchasing",
        legalEntity: 'Sole Proprietorship',
        date: "65511"
    }
];


function RegisterTenders() {
    const history = useHistory()
    const columns = [

        {
            title: 'Procuring Entity',
            dataIndex: 'entityName',
            key: 'entityName',
            width: '30%',

        },
        {
            title: 'Reference Number',
            dataIndex: 'tin',
            key: 'tin',
            width: '10%',

        },
        {
            title: 'Lot Name',
            dataIndex: 'legalEntity',
            key: 'legalEntity',
            width: "30%"
        },

        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            width: "20%"
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
        },
        {
            title: 'Bid Type',
            dataIndex: 'bidType',
            key: 'bidType',
            width: "20%"
        },
    ];
    const rowClickConfig = (record) => {
        const type = record.bidType.toLowerCase();
        return {
            onClick: () => history.push(`/workspace/registered-tenders/${type}/registered/608894d0-37b0-41fb-b652-f72cd2cebeca/09025ac0-1e8f-4db0-a98a-2bbdd9541133`)
        };
    };
    return (
        <main className="flex-1 pl-3 min-w-[21rem]">
            <div className="w-full min-h-screen p-3 bg-white border">
                <Card title='Registered Bids' className='w-full py-2 px-1' >
                    <Search placeholder="search here" loading={false} />
                    <Table columns={columns} dataSource={data} onRow={rowClickConfig} className='mt-4 overflow-x-scroll' />
                </Card>
            </div>
        </main>
    )
}

export default RegisterTenders