import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

const Search = () => {
  let history = useHistory();


  const [keyword, setKeyword] = useState("");

  const searchSubmitHandler = (e) => {
    e.preventDefault();

    if (keyword.trim()) {
      console.log(keyword)
      history.push(`/lot/${keyword}`);
    } else {
      console.log(`keyword is not define`);
      history.push("/lot");
    }

  };

  return (
    <>
      <form className='searchBox flex items-center' onSubmit={searchSubmitHandler}>
        {/* <i className="fa fa-search"></i> */}
        <input
          type="text"
          className="form-control"
          placeholder="Looking for a Tender ? Search Now"
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button className="py-[0.36rem] px-4 w-auto !bg-DaDUprimary text-white rounded" type='submit'   >Search</button>
      </form>   </>
  );
};

export default Search