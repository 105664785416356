import React from 'react'
import { Card, Input, Table } from 'antd'

const { Search } = Input;


const data = [
    {
        key: '1',
        ProcuringEntity: '0041255271',
        ReferenceNumber: 'peace security plc',
        legalEntity: 'Private Limited Company',
        SubmissionDeadline: "13.10.2024",
        BidType: "sample type",
        Description: "86823"
    },
    {
        key: '2',
        ProcuringEntity: '0087033164',
        ReferenceNumber: "ABIYOT TESEMA SHIFERAW",
        LotName: 'Sole Proprietorship',
        SubmissionDeadline: "13.10.2024",
        BidType: "sample type",
        Description: "62712"
    },
    {
        key: '3',
        ProcuringEntity: '0062401805',
        ReferenceNumber: 'SIFRASH WORKNEH ABITEW',
        LotName: 'Sole Proprietorship',
        SubmissionDeadline: "13.10.2024",
        BidType: "sample type",
        Description: "61845"
    },
    {
        key: '4',
        ProcuringEntity: '0067948231',
        ReferenceNumber: 'GOGEB STARS ENGINEERING PLC',
        LotName: 'Private Limited Company',
        SubmissionDeadline: "13.10.2024",
        BidType: "sample type",
        Description: "51114"
    },
    {
        key: '4',
        ProcuringEntity: '0050513547',
        ReferenceNumber: 'YONAS ADISSU ORDOFA',
        LotName: 'Sole Proprietorship',
        SubmissionDeadline: "13.10.2024",
        BidType: "sample type",
        Description: "65511"
    }
];


function Bookmark() {

    const columns = [

        {
            title: 'Procuring Entity',
            dataIndex: 'ProcuringEntity',
            key: 'ProcuringEntity',
            width: '30%',

        },
        {
            title: 'Reference Number',
            dataIndex: 'ReferenceNumber',
            key: 'ReferenceNumber',
            width: '10%',

        },
        {
            title: 'Lot Name',
            dataIndex: 'LotName',
            key: 'LotName',
            width: "20%"
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            width: '40%',
        },
        {
            title: 'Submission Deadline',
            dataIndex: 'SubmissionDeadline',
            key: 'SubmissionDeadline',
            width: '10%',
        },
        {
            title: 'Bid Type',
            dataIndex: 'BidType',
            key: 'BidType',
            width: '10%',
        },
    ];
    const rowClickConfig = (record) => {
        return {
            onClick: () => alert('Row Clicked')
        };
    };
    return (
        <main className="flex-1 pl-3 min-w-[21rem]">
            <div className="w-full min-h-screen p-3 bg-white border">
                <Card title='Bookmarks' className='w-full py-2 px-1' >
                    <Search placeholder="search here" loading={false} />
                    <Table columns={columns} dataSource={data} onRow={rowClickConfig} className='mt-4 overflow-x-scroll' />
                </Card>
            </div>
        </main>
    )
}

export default Bookmark