import React, { useContext, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { RiAuctionFill } from 'react-icons/ri';
import { IoMenu } from "react-icons/io5";
import "./navbarstyle.scss";
import DropDown from '../components/dropdown';
import { UserContext } from '../../App';

import DaDUlogo from '../../assets/img/logo_1.svg'

const Navbar = () => {
  const { state, dispatch } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory()
  const location = useLocation()

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = (value) => {
    history.push(value)
  }
  const handleLanguage = (value) => {
    alert(`Selected Language is ${value}`)
  }
  const profileLink = [
    { id: 1, name: 'User Profile', value: "/profile" },
    { id: 2, name: 'Logout', value: "/logout" }
  ]

  const lotLink = [
    { id: 1, name: 'Add Lot', value: "/addlot" },
    { id: 2, name: 'My Lot', value: "/updatelot" }
  ]

  const moreLink = [
    { id: 1, name: 'Supplier List', value: "/suppliers" },
    { id: 2, name: 'Resources', value: "/resources" },
    { id: 7, name: 'Bid Status', value: "/bidstatus" },
    { id: 3, name: 'About', value: "/about" },
    { id: 4, name: 'Our Services', value: "/service" },
    { id: 5, name: 'Feedback', value: "/feedback" },
    { id: 6, name: 'Contact Us', value: "/contact" },
  ]
  const languageLink = [
    { id: 1, name: 'English', value: "En" },
    { id: 2, name: 'Afan Oromo', value: "AO" },
    { id: 3, name: 'አመረኘ', value: "Am" },
  ]

  const RenderMenu = () => {
    if (state) {
      return (
        <div className='flex lg:flex-row flex-col lg:gap-12 '>
          <div className='flex lg:flex-row flex-col lg:!gap-0 gap-3'>
            {/* USER LOGGED IN */}
            <li className="px-3 mx-auto  flex items-center">
              <NavLink exact className={`no-underline hover:text-gray-600 text-DaDUprimary ${location.pathname === '/home' ? 'font-bold border-b-DaDUprimary border-b-2' : ''}`} aria-current="page" to="/home" >Home</NavLink>
            </li>
            {/* <DropDown dropData={lotLink} text='Lots' handleButtonClick={handleButtonClick} /> */}

            <li className="px-3 mx-auto  flex items-center">
              <NavLink exact className={`no-underline hover:text-gray-600 text-DaDUprimary ${location.pathname === '/lot' ? 'font-bold border-b-DaDUprimary border-b-2' : ''}`} to="/lot">Tenders</NavLink>
            </li>
            <li className="px-3 mx-auto  flex items-center">
              <NavLink exact className={`no-underline hover:text-gray-600 text-DaDUprimary ${location.pathname === '/supplier-registration' ? 'font-bold border-b-DaDUprimary border-b-2' : ''}`} to="/supplier-registration">Registration </NavLink>
            </li>
            <li className="px-3 mx-auto  flex items-center">
              <NavLink exact className={`no-underline hover:text-gray-600 text-DaDUprimary ${location.pathname === '/workspace' ? 'font-bold border-b-DaDUprimary border-b-2' : ''}`} to="/workspace">Workspace</NavLink>
            </li>
            <li className="px-1 mx-auto  flex items-center">
              <DropDown dropData={moreLink} text='More' handleButtonClick={handleButtonClick} />
            </li>
          </div>
          <div className='flex lg:flex-row flex-col'>
            <li className="px-1 mx-auto  flex items-center">
              <DropDown dropData={profileLink} text='Profile' handleButtonClick={handleButtonClick} />
            </li>
            <li className="px-1 mx-auto  flex items-center">
              <DropDown dropData={languageLink} text='Language' handleButtonClick={handleLanguage} />
            </li>
          </div>
        </div>

      )
    } else {
      return (
        <div className='flex lg:flex-row flex-col gap-12'>
          <div className='flex lg:flex-row flex-col'>
            {/* USER LOGGED IN */}
            <li className="px-3 mx-auto licls flex items-center">
              <NavLink exact className="no-underline hover:text-gray-600 text-DaDUprimary " aria-current="page" to="/home" >Home</NavLink>
            </li>
            <li className="px-3 mx-auto licls flex items-center">
              <NavLink exact className="no-underline hover:text-gray-600 text-DaDUprimary" to="/lot">Tenders</NavLink>
            </li>
            <li className="px-3 mx-auto licls flex items-center">
              <NavLink exact className="no-underline hover:text-gray-600 text-DaDUprimary" to="/supplier-registration">Registration </NavLink>
            </li>
            <DropDown dropData={moreLink} text='More' handleButtonClick={handleButtonClick} />
          </div>
          <div className='flex lg:flex-row flex-col'>
            <DropDown dropData={languageLink} text='English' handleButtonClick={handleLanguage} />
            <li className=" mx-auto ">
              <div className='no-underline hover:!text-DaDUprimary border-2 border-DaDUprimary hover:bg-transparent rounded-lg bg-DaDUprimary text-white'>
                <NavLink to="/signup" className=' no-underline hover:!text-DaDUprimary py-1 px-2 flex text-white' id="bt1">
                  Sign Up
                </NavLink>
              </div>
            </li>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="container-fluid nav_bg navbarpg">
      <div className='row'>
        <div className='col-12 mx-auto'>
          <nav className="flex bg-white">
            <div className="container-fluid flex items-center justify-between text-xl py-2">
              {/* <img
                src={DaDUlogo}
                alt='logo'
                className='w-16 h-16'
              /> */}
              <NavLink className="navbar-logo flex flex-col items-center " to="/">
                <RiAuctionFill />
                <span className='text-[26px]'>
                  DaDU Proc
                </span>
              </NavLink>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="">
                <ul className="lg:flex hidden ms-auto mb-2 mb-lg-0 ">
                  <RenderMenu />
                </ul>
                <button className='block lg:hidden border-1 border-DaDUprimary text-DaDUprimary p-1 rounded'
                  onClick={toggleMenu}
                >
                  <IoMenu />
                </button>
                {isOpen && (
                  <div className="absolute z-10 top-16 right-0 mt-2 w-[15rem] h-auto bg-white shadow-lg rounded-md">
                    <ul className="py-2">
                      <RenderMenu />
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </nav>

        </div>
      </div>
    </div>
  );
};

export default Navbar;
