import React, { useState } from 'react';
import { Card, Table } from 'antd';

const dataSource = [
    {
        key: '1',
        No: 1,
        AgendaItems: 'Sample Agenda',
        Decision: 'Sample Decision',
    }

];

const columns = [
    {
        title: 'No.',
        dataIndex: 'No',
        key: 'No',
    },
    {
        title: 'Agenda Items',
        dataIndex: 'AgendaItems',
        key: 'AgendaItems',
    },
    {
        title: 'Decision',
        dataIndex: 'Decision',
        key: 'Decision',
    },
];

const tabListNoTitle = [
    {
        key: 'VideoConference',
        label: 'Video Conference',
    },
    {
        key: 'Minutes',
        label: 'Minutes',
    }
];
const contentListNoTitle = {
    VideoConference: <div className="flex flex-col h-full items-center justify-center ">
        <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
            <i className="anticon text-4xl text-gray-600 ">
                <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                    <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                    </path>
                </svg>
            </i>
        </div>
        <div className="mt-6 text-center">
            <p className="text-lg font-semibold mb-0">
                No Pre-bid conference for this lot
            </p>
            <p >
                Pre-bid conference is not allowed
            </p>
        </div>
    </div>,
    Minutes: <Card
        title="Bid Conference Minutes"
        style={{
            width: '100%',
        }}
    >
        <Table dataSource={dataSource} columns={columns} />;
    </Card>,
};

function Conference() {
    const [activeTabKey2, setActiveTabKey2] = useState('VideoConference');
    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };
    return (
        <Card
            style={{
                width: '100%',
            }}
            bordered={false}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey2}
            // tabBarExtraContent={<a href="#">More</a>}
            onTabChange={onTab2Change}
            tabProps={{
                size: 'middle',
            }}
        >
            {contentListNoTitle[activeTabKey2]}
        </Card>
    )
}

export default Conference