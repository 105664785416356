import React, { useState, useRef } from 'react';
import { Card, Input, Button, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import MetaData from '../MetaData/MetaData';
import { RollbackOutlined } from '@ant-design/icons';

const tabList = [
    {
        key: 'tab1',
        tab: 'Active Suppliers',
    },
    {
        key: 'tab2',
        tab: 'Debarred Suppliers',
    },
];


const data = [
    {
        key: '1',
        tin: '0041255271',
        companyName: 'peace security plc',
        address: 'Private Limited Company',
        nationality: "Ethiopia",
        areaofBusiness: "Goods  Consultancy Services",
        sector: "(86823) - Security and claning service  (86823) - Security and claning service  (86821) - Local labor recruitment and linkage activities"
    },
    {
        key: '2',
        tin: '0087033164',
        companyName: "ABIYOT TESEMA SHIFERAW",
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(62712) - Retail trade of vehicles accessories and décor  (62361) - Retail trade of construction materials"
    },
    {
        key: '3',
        tin: '0062401805',
        companyName: 'SIFRASH WORKNEH ABITEW',
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(61845) - wholesale trade in cosmetics, perfumery and sanitary"
    },
    {
        key: '4',
        tin: '0067948231',
        companyName: 'GOGEB STARS ENGINEERING PLC',
        address: 'Private Limited Company',
        nationality: "Ethiopia",
        areaofBusiness: "Works  Goods",
        sector: "51114) - ጠቅላላ ስራ ተቋራጭ ከውሀ  ስራዎች በስተቀር  (61641) - የጽህፈት መሳሪዎች፣ ወረቀትና የወረቀት ውጤቶች ጅምላ ንግድ "
    },
    {
        key: '4',
        tin: '0050513547',
        companyName: 'YONAS ADISSU ORDOFA',
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "65511) - (65511)Import trade in material metal and non metal scraps  (65321) - Import of household and office appliances and furnishings; (65511) - (65511)Import trade in material metal and non metal scraps  (65512) - Import trade in logs and timber; (65511) - (65511)Import trade in material metal and non metal scraps  (65311) - Importtrade intextile fiber cotton thread and  apparel(65311) "
    },
    {
        key: '4',
        tin: '0067948231',
        companyName: 'GOGEB STARS ENGINEERING PLC',
        address: 'Private Limited Company',
        nationality: "Ethiopia",
        areaofBusiness: "Works  Goods",
        sector: "51114) - ጠቅላላ ስራ ተቋራጭ ከውሀ  ስራዎች በስተቀር  (61641) - የጽህፈት መሳሪዎች፣ ወረቀትና የወረቀት ውጤቶች ጅምላ ንግድ "
    },
    {
        key: '4',
        tin: '0050513547',
        companyName: 'YONAS ADISSU ORDOFA',
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "65511) - (65511)Import trade in material metal and non metal scraps  (65321) - Import of household and office appliances and furnishings; (65511) - (65511)Import trade in material metal and non metal scraps  (65512) - Import trade in logs and timber; (65511) - (65511)Import trade in material metal and non metal scraps  (65311) - Importtrade intextile fiber cotton thread and  apparel(65311) "
    },
    {
        key: '3',
        tin: '0062401805',
        companyName: 'SIFRASH WORKNEH ABITEW',
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(61845) - wholesale trade in cosmetics, perfumery and sanitary"
    },
    {
        key: '2',
        tin: '0087033164',
        companyName: "ABIYOT TESEMA SHIFERAW",
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(62712) - Retail trade of vehicles accessories and décor  (62361) - Retail trade of construction materials"
    },
    {
        key: '3',
        tin: '0062401805',
        companyName: 'SIFRASH WORKNEH ABITEW',
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(61845) - wholesale trade in cosmetics, perfumery and sanitary"
    },
    {
        key: '3',
        tin: '0062401805',
        companyName: 'SIFRASH WORKNEH ABITEW',
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(61845) - wholesale trade in cosmetics, perfumery and sanitary"
    },
    {
        key: '1',
        tin: '0041255271',
        companyName: 'peace security plc',
        address: 'Private Limited Company',
        nationality: "Ethiopia",
        areaofBusiness: "Goods  Consultancy Services",
        sector: "(86823) - Security and claning service  (86823) - Security and claning service  (86821) - Local labor recruitment and linkage activities"
    },
    {
        key: '2',
        tin: '0087033164',
        companyName: "ABIYOT TESEMA SHIFERAW",
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(62712) - Retail trade of vehicles accessories and décor  (62361) - Retail trade of construction materials"
    },
    {
        key: '2',
        tin: '0087033164',
        companyName: "ABIYOT TESEMA SHIFERAW",
        address: 'Sole Proprietorship',
        nationality: "Ethiopia",
        areaofBusiness: "Goods",
        sector: "(62712) - Retail trade of vehicles accessories and décor  (62361) - Retail trade of construction materials"
    },
];

const { Search } = Input;

function Suppliers() {

    const history = useHistory()

    const [activeTabKey1, setActiveTabKey1] = useState('tab1');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const onTab1Change = (key) => {
        setActiveTabKey1(key);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'Tin',
            dataIndex: 'tin',
            key: 'tin',
            width: '10%',
            ...getColumnSearchProps('tin'),
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '20%',
            ...getColumnSearchProps('companyName'),
        },
        {
            title: 'Legal Form',
            dataIndex: 'address',
            key: 'address',
            ...getColumnSearchProps('address'),
            sorter: (a, b) => a.address.length - b.address.length,
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Nationality',
            dataIndex: 'nationality',
            key: 'nationality',
            width: '20%',
            ...getColumnSearchProps('nationality'),
        },

        {
            title: 'Area of Business',
            dataIndex: 'areaofBusiness',
            key: 'areaofBusiness',
            width: '20%',
            ...getColumnSearchProps('areaofBusiness'),
        },

        {
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
            width: '40%',
            ...getColumnSearchProps('sector'),
        },
    ];
    const rowClickConfig = (record) => {
        return {
            onClick: () => history.push('/suppliers/detail/active/65b24d1633044e3d009059d1'),
        };
    };
    const rowClickDebarred = (record) => {
        return {
            onClick: () => history.push('/suppliers/detail/debarred/65b24d1633044e3d009059d1'),
        };
    };
    const contentList = {
        tab1: <Table columns={columns} dataSource={data} onRow={rowClickConfig} className='overflow-x-scroll' />,
        tab2: <Table columns={columns} dataSource={data} onRow={rowClickDebarred} className='overflow-x-scroll' />,
    };

    return (
        <div className='bg-DaDUprimary bg-opacity-10 pb-4'>
            <MetaData title={'SUPPLIER LIST'}></MetaData>
            <div className="container mx-auto ">
                <div className=' product_section layout_padding my-0 pt-4'>
                    <div className="section-title" data-aos="fade-up">
                        <h2>Supplier List</h2>
                        <p>Check the Suppliers!</p>
                    </div>
                </div>
                <div className="justify-between mb-2 md:flex hidden">
                    <div className="w-1/2 flex justify-between mr-3 rounded border px-4 py-2" style={{ backgroundColor: '#fef8dd85' }}>
                        <div className="flex flex-col w-1/3 pr-6">
                            <p className="text-2xl text-gray-900">15,116
                            </p>
                            <p >Total Active Suppliers
                            </p>
                        </div><div className="flex flex-col w-1/3 border-l border-r px-6"><p className="text-2xl text-gray-900">15,006
                        </p>
                            <p >Active Local Suppliers
                            </p>
                        </div>
                        <div className="flex flex-col w-1/3 pl-6">
                            <p className="text-2xl text-gray-900">110
                            </p>
                            <p >Active Foreign Suppliers
                            </p>
                        </div>
                    </div><div className="w-1/2 flex justify-between rounded border px-4 py-2" style={{ backgroundColor: '#fef8dd85' }}>
                        <div className="flex flex-col w-1/3 pr-6">
                            <p className="text-2xl text-gray-900">30
                            </p>
                            <p >Total Debarred Suppliers
                            </p>
                        </div><div className="flex flex-col w-1/3 border-l border-r pl-6"><p className="text-2xl text-gray-900">30
                        </p><p >Debarred Local Suppliers
                            </p>
                        </div><div className="flex flex-col w-1/3 pl-6"><p className="text-2xl text-gray-900">0
                        </p><p >Debarred Foreign Suppliers
                            </p>
                        </div>
                    </div>

                </div>
                <Card
                    style={{
                        width: '100%',
                    }}
                    title="Suppliers List"
                    extra={<Button className='flex items-center border-0 text-red-600'><RollbackOutlined />Back</Button>}
                    tabList={tabList}
                    activeTabKey={activeTabKey1}
                    onTabChange={onTab1Change}
                >
                    <Search placeholder="search here" loading={false} />
                    <div className='mt-4'>
                        {contentList[activeTabKey1]}
                    </div>
                </Card>

            </div>
        </div>
    )
}

export default Suppliers