import React from 'react'

function EvaluationReport() {
    return (
        <div className="h-auto px-32 py-16 -m-2 bg-gray-100">
            <div className="flex flex-col items-center justify-center min-h-screen p-4 -m-2 text-2xl bg-gray-100 ">
                <div className="flex flex-col items-center justify-center w-full py-12 -mt-64 bg-white border rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="w-12 h-12 mb-6">
                        <path fill="#8bb7f0" d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"></path>
                        <path fill="#4e7ab5" d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"></path>
                        <path fill="#fff" d="M20 10A2 2 0 1 0 20 14A2 2 0 1 0 20 10Z"></path>
                        <g>
                            <path fill="#fff" d="M22 28L22 16 17 16 17 17 18 17 18 28 17 28 17 29 23 29 23 28z"></path>
                        </g>
                    </svg>
                    <p>
                        Currently, there is no evaluation report for the current envelope
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EvaluationReport