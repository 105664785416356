import React, { useEffect, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import "./lotstyle.scss";
import Search from './Search';
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';

import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from '../Loader/Loader';

import { useAlert } from 'react-alert';
import MetaData from '../MetaData/MetaData';

const data = [
  {
    key: '1',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "sample",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "sample",
    marketApproach: "sample",
    submissionDeadline: "sample"
  },
  {
    key: '2',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '3',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '4',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '5',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '6',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '7',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '8',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "1",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '9',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
  {
    key: '10',
    procurementRefNo: 'John Brown',
    lotNo: 32,
    procurementTitle: "",
    procuringEntity: 'New York No. 1 Lake Park',
    procurementCategory: "",
    marketApproach: "",
    submissionDeadline: ""
  },
];

const Lot = ({ match }) => {

  const dispatch = useDispatch();

  const alert = useAlert();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 2500000]);
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterEntity, setFilterEntity] = useState([
    {
      text: 'Joe',
      value: 'Joe',
    },
    {
      text: 'Jim',
      value: 'Jim',
    }
  ])
  const [filterCategory, setFilterCategory] = useState([
    {
      text: 'Goods',
      value: 'Goods',
    },
    {
      text: 'Works',
      value: 'Works',
    },
    {
      text: 'Consultancy Services',
      value: 'Consultancy Services',
    },
    {
      text: 'Non-Consultancy Services',
      value: 'Non-Consultancy Services',
    }
  ])
  const [filterApproch, setFilterApproch] = useState([
    {
      text: 'National',
      value: 'National',
    }, {
      text: 'International',
      value: 'International',
    },
  ])
  const searchInput = useRef(null);

  const { loading, error, products, productCount, resultPerPage } = useSelector(
    (state) => state.products
  );
  const keyword = match.params.keyword;
  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  useEffect(() => {

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getProduct(keyword, currentPage, price, category));

  }, [dispatch, keyword, currentPage, price, category, alert, error]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Procurement Ref. No.',
      dataIndex: 'procurementRefNo',
      key: 'procurementRefNo',

      ...getColumnSearchProps('procurementRefNo'),
    },
    {
      title: 'Lot No.',
      dataIndex: 'lotNo',
      key: 'lotNo',

      ...getColumnSearchProps('lotNo'),
    },
    {
      title: 'Procurement Title',
      dataIndex: 'procurementTitle',
      key: 'procurementTitle',
      ...getColumnSearchProps('procurementTitle')
    },
    {
      title: 'Procuring Entity',
      dataIndex: 'procuringEntity',
      key: 'procuringEntity',
      filters: filterEntity,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
    },
    {
      title: 'Procurement Category',
      dataIndex: 'procurementCategory',
      key: 'procurementCategory',
      filters: filterCategory,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
    },
    {
      title: 'Market Approach',
      dataIndex: 'marketApproach',
      key: 'marketApproach',
      filters: filterApproch,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
    },
    {
      title: 'Submission Deadline',
      dataIndex: 'submissionDeadline',
      key: 'submissionDeadline',
      ...getColumnSearchProps('submissionDeadline')
    },
  ];
  const rowClickConfig = (record) => {
    return {
      onClick: () => history.push('/product/65b24d1633044e3d009059d1'),
    };
  };
  return (
    <>{loading ? (<Loader />) : (
      <div className='bg-DaDUprimary bg-opacity-10'>
        {/* <h1> Lot Page</h1> */}
        <MetaData title="Tenders"></MetaData>

        <div className=" lotcls" data-aos="fade-up" data-aos-delay="400">
          <div className='row'>
            <div className='col-10 mx-auto'>

              {/* Heading Section */}

              <div className="section-title" data-aos="fade-up">
                <h2>Tenders</h2>
                <p>Start Bidding Now!</p>
              </div>

              <div className="hidden md:block md:flex justify-between mb-2 ">
                <div className="w-1/2 flex justify-between mr-3 rounded border px-4 py-2 shadow-DaDUshadow" style={{ backgroundColor: "#fef8dd85" }}>
                  <div className="flex flex-col cursor-pointer hover:font-semibold hover:text-primary-400 w-1/3 pr-4 font-semibold text-primary-400" >
                    <p className="text-2xl text-gray-900 cursor-pointer">368</p>
                    <p className="cursor-pointer">Total Active Tenders</p>
                  </div>
                  <div className="flex flex-col cursor-pointer hover:font-semibold hover:text-primary-400 w-1/3 border-l border-r px-4" >
                    <p className="text-2xl text-gray-900 cursor-pointer">85</p>
                    <p className="cursor-pointer">Tenders Published Today</p>
                  </div>
                  <div className="flex flex-col cursor-pointer hover:font-semibold hover:text-primary-400 w-1/3 pl-4" >
                    <p className="text-2xl text-gray-900 cursor-pointer">5</p>
                    <p className="cursor-pointer">Tenders Closing Today</p>
                  </div>
                </div>
                <div className="w-1/2 flex justify-between rounded border px-4 py-2 shadow-DaDUshadow" style={{ backgroundColor: '#fef8dd85' }}>
                  <div className="flex flex-col cursor-pointer hover:font-semibold hover:text-primary-400 w-1/3 pr-4" >
                    <p className="text-2xl text-gray-900 cursor-pointer">2</p
                    ><p className="cursor-pointer">Tenders Opening Today</p>
                  </div>
                  <div className="flex flex-col cursor-pointer hover:font-semibold hover:text-primary-400 w-1/3 border-l border-r pl-4" >
                    <p className="text-2xl text-gray-900 cursor-pointer">10,715</p>
                    <p className="cursor-pointer">Total Tenders Published</p>
                  </div>
                  <div className="flex flex-col cursor-pointer hover:font-semibold hover:text-primary-400 w-1/3 pl-4" >
                    <p className="text-2xl text-gray-900 cursor-pointer">8</p>
                    <p className="cursor-pointer">Tenders Cancelled</p>
                  </div>
                </div>
              </div>
              <section className="lots-section ">
                <div className="lots-list-content lots-container">
                  <section className="product-lots">
                    <div className="row">
                      <div className="col-12">
                        <div className="product-topbar d-flex align-items-center justify-content-between">
                          {/* <!-- Total Products --> */}
                          <div className="total-products">
                            {(keyword) ? (
                              <p>Results of <span>{keyword}</span></p>
                            ) : (
                              <p><span>{productCount}</span> Tender found</p>
                            )}
                          </div>
                          {/* <!-- Sorting --> */}
                          <div className='my-3'>
                            <Search />
                          </div>

                        </div>
                      </div>
                    </div>
                    <Table className='overflow-x-scroll' columns={columns} dataSource={data} onRow={rowClickConfig} />

                  </section>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )}</>

  );
};

export default Lot;
