import React from 'react'
import { Table } from 'antd'
import { RiAuctionFill } from 'react-icons/ri'

const dataSource = [];

const columns = [
    {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank',
    },
    {
        title: 'Lot Name',
        dataIndex: 'lotName',
        key: 'lotName',
    },
    {
        title: 'Supplier Name',
        dataIndex: 'supplierName',
        key: 'supplierName',
    },
    {
        title: 'Negotiation Status',
        dataIndex: 'negotiationStatus',
        key: 'negotiationStatus',
    },
    {
        title: 'Award Status',
        dataIndex: 'awardStatus',
        key: 'awardStatus',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
    },
];

function AwardNotice() {
    return (
        <div className="h-screen px-6 py-16 -m-2 bg-gray-100">
            <div className="bg-white border rounded-lg">
                <div className="p-5 leading-loose bg-white">
                    <div className="flex items-center justify-between"><div >
                        <div className='flex flex-col items-center text-DaDUprimary'>
                            <RiAuctionFill size={26} />
                            <span className='text-[20px]'>
                                DaDU Proc
                            </span>
                        </div>
                    </div>
                        <p className="text-lg font-semibold text-gray-800">Award Announcement</p>
                        <div >
                            <p className="flex items-center">
                                <span className="font-semibold text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 50" className="w-10 h-4 mr-2 text-gray-600 fill-current">
                                        <path d="M12 0C10.90625 0 10 0.90625 10 2L10 4L4 4C2.839844 4 2 4.839844 2 6L2 13L48 13L48 6C48 4.839844 47.160156 4 46 4L40 4L40 2C40 0.90625 39.09375 0 38 0L36 0C34.90625 0 34 0.90625 34 2L34 4L16 4L16 2C16 0.90625 15.09375 0 14 0 Z M 12 2L14 2L14 8L12 8 Z M 36 2L38 2L38 8L36 8 Z M 2 15L2 46C2 47.15625 2.839844 48 4 48L46 48C47.15625 48 48 47.15625 48 46L48 15 Z M 30.1875 30L37.8125 30C38.46875 30 39 30.53125 39 31.1875L39 38.8125C39 39.46875 38.46875 40 37.8125 40L30.1875 40C29.53125 40 29 39.46875 29 38.8125L29 31.1875C29 30.53125 29.53125 30 30.1875 30Z"></path>
                                    </svg>
                                </span>
                                {" "} Jan 01, 1970 03:00 AM
                            </p>
                        </div>
                    </div>
                    <div >
                        <p className="flex items-center">
                            <span className="mr-2">Reference Number:</span>
                            {" "}WDU-ICB-G-0012-2016-BID
                        </p>
                        <p className="flex items-center">
                            <span className="mr-2">Procuring Entity:</span>
                        </p>
                        <p className="flex items-center">
                            <span className="mr-2">Bid Name:</span>
                        </p>
                    </div>
                    <div className="my-4">
                        <p > Thank you for participating in the above-mentioned tender procedure. </p>
                        <p > Your average scores &amp; Status awarded by the evaluators according to the award criteria were as follows: </p>
                    </div>
                    <Table dataSource={dataSource} columns={columns} />;
                    <div className="my-4">
                        <p > If you have complaints on the evaluation result, you can submit within 5 days starting from today. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AwardNotice