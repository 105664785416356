import React from 'react'
import { Card, Input } from 'antd'

const { Search } = Input;

function NotificationList() {
    return (
        <main className="flex-1 pl-3 min-w-[21rem]">
            <div className="w-full min-h-screen p-3 bg-white border">
                <Card title='List of Notification' className='w-full py-2 px-1' >
                    <Search placeholder="search here" loading={false} />
                </Card>
            </div>
        </main>
    )
}

export default NotificationList