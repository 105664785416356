import React from 'react'
import { Card, Button, message, Upload } from 'antd'
import { UploadOutlined, SaveOutlined } from '@ant-design/icons';

function Evidence() {

    const props = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <div className="flex flex-wrap gap-2">
            <div className="md:w-1/3 w-full ">
                <Card>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Document Type </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Valid Trade License </div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Description </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Valid Trade License </div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed mb-2 ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Documents </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white">
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <Button className="border-1 border-DaDUprimary bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center">
                            <SaveOutlined />
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
            <div className="md:w-1/3 w-full ">
                <Card>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Document Type </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Vat Registration Certificate </div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Description </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Vat Registration Certificate</div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed mb-2 ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Documents </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white">
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <Button className="border-1 border-DaDUprimary bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center">
                            <SaveOutlined />
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
            < div className="md:w-1/3 w-full ">
                <Card>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Document Type </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Valid Tax Clearance Certificate </div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Description </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Valid Tax Clearance Certificate </div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed mb-2 ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Documents </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white">
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <Button className="border-1 border-DaDUprimary bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center">
                            <SaveOutlined />
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
            <div className="md:w-1/3 w-full ">
                <Card>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Document Type </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Business Organization Registration Certificate</div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Description </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white"> Business Organization Registration Certificate</div>
                    </div>
                    <div className="flex justify-between border-t border-b border-dashed mb-2 ">
                        <div className="w-1/3 p-2 font-semibold text-gray-600 bg-gray-100 border-r"> Documents </div>
                        <div className="w-2/3 p-2 text-gray-700 bg-white">
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <Button className="border-1 border-DaDUprimary bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center">
                            <SaveOutlined />
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        </div>


    )
}

export default Evidence