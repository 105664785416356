import React, { useState } from 'react'
import { Card, Input, Table, Button, Modal, Form, Select, Upload, Space } from 'antd'
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
const { Search } = Input;


const data = [
    {
        key: '1',
        action: <DownloadOutlined style={{ cursor: 'pointer' }} />,
        entityName: 'peace security plc',
        legalEntity: 'Private Limited Company',
        date: "86823"
    },
    {
        key: '2',
        action: <DownloadOutlined style={{ cursor: 'pointer' }} />,
        entityName: "ABIYOT TESEMA SHIFERAW",
        legalEntity: 'Sole Proprietorship',
        date: "62712"
    },
    {
        key: '3',
        action: <DownloadOutlined style={{ cursor: 'pointer' }} />,
        entityName: 'SIFRASH WORKNEH ABITEW',
        legalEntity: 'Sole Proprietorship',
        date: "61845"
    },
    {
        key: '4',
        action: <DownloadOutlined style={{ cursor: 'pointer' }} />,
        entityName: 'GOGEB STARS ENGINEERING PLC',
        legalEntity: 'Private Limited Company',
        date: "51114"
    },
    {
        key: '4',
        action: <DownloadOutlined style={{ cursor: 'pointer' }} />,
        entityName: 'YONAS ADISSU ORDOFA',
        legalEntity: 'Sole Proprietorship',
        date: "65511"
    }
];


function Locker() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const columns = [

        {
            title: 'Name',
            dataIndex: 'entityName',
            key: 'entityName',
            width: '30%',

        },
        {
            title: 'Evidence Type',
            dataIndex: 'legalEntity',
            key: 'legalEntity',
            width: "30%"
        },

        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '20%',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            render: (text, record) => (
                <Button onClick={() => DownloadClickConfig()} className='border-1 border-DaDUprimary text-DaDUprimary hover:!text-white hover:bg-DaDUprimary'>
                    <Space size="middle">
                        {record.action}
                    </Space>
                </Button>
            ),
        },
    ];

    const DownloadClickConfig = (record) => {
        alert('Download Clicked')
    };

    return (
        <main className="flex-1 pl-3 min-w-[21rem]">
            <div className="w-full min-h-screen p-3 bg-white border">
                <Card title='My Locker' className='w-full py-2 px-1'
                    extra={
                        <Button onClick={showModal} className='bg-green-800 rounded px-2 py-1 text-white w-auto flex items-center gap-1'>
                            <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="plus-circle" aria-hidden="true">
                                <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                            </svg>

                            New
                        </Button>}
                >
                    <Search placeholder="search here" loading={false} />
                    <Table columns={columns} dataSource={data} className='mt-4 overflow-x-scroll' />
                </Card>
            </div>
            <Modal title="Add New File" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label="Evidence Type" name="evidenceType">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select" name="select">
                        <Select>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Upload"
                        name="upload"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload action="/upload.do" listType="picture-card">
                            <button
                                style={{
                                    border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </main>
    )
}

export default Locker