import React, { useState } from 'react'
import { Collapse, Button, Descriptions, Form, DatePicker } from 'antd'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



function SiteVisit() {

    const [data, setData] = useState('Sample, text')


    const handleChange = (value) => {
        setData(value)
    }

    const descriptionData = [
        {
            key: '1',
            label: 'Lot Name',
            children: 'Procurement of Supply, Installation and Commissioning of Networking Infrastructure and Modular Data Center',
        },
        {
            key: '2',
            label: 'Site Place',
            children: 'Woldia University, Main Campus, Mersa College of Agriculture, Mersa Campus.',
        },
        {
            key: '3',
            label: 'Visitation Status',
            children: 'Visitation Status',
        }
    ];


    const items = [
        {
            key: '1',
            label: 'New Site Visit',
            children: <div>
                <Descriptions title="Site Visit Place" items={descriptionData} />
                <Form
                    layout="vertical"
                >
                    <Form.Item
                        label="Visitation Data"
                        name="VisitationDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        label="Visitors"
                        name="Visitors"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <ReactQuill value={data} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item>
                        <Button className='py-2 px-4 bg-DaDUprimary border-2 border-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center font-bold'>Save</Button>
                    </Form.Item>
                </Form>
            </div>,
        }

    ];


    return (
        <Collapse
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => isActive ? <Button className='border-2 border-DaDUprimary !text-DaDUprimary'>Collapse</Button> : <Button className='border-2 border-DaDUprimary !text-DaDUprimary'>Expande</Button>}
            expandIconPosition={'end'}
            items={items}
        />
    )
}

export default SiteVisit