import React from 'react'
import { Card, Table } from 'antd'

const { Meta } = Card


const data = [
    {
        key: '1',
        constition: 'Mandatory',
        requiremnt: 'በዘርፉ የተሰማራ እና የዘመኑን ግብር የከፈለ መሆን ይኖርበታል',

    },
    {
        key: '2',
        constition: 'Mandatory',
        requiremnt: "Sample Requirement",

    },
    {
        key: '3',
        constition: 'Mandatory',
        requiremnt: 'በዘርፉ የተሰማራ እና የዘመኑን ግብር የከፈለ መሆን ይኖርበታል',

    },
    {
        key: '4',
        constition: 'Mandatory',
        requiremnt: 'Sample Requirement',

    },
    {
        key: '4',
        constition: 'Mandatory',
        requiremnt: 'Sample Requirement',

    }
];

function Criteria() {
    const columns = [

        {
            title: 'Requirement',
            dataIndex: 'requiremnt',
            key: 'requiremnt',
            width: '50%'
        },
        {
            title: 'Condition',
            dataIndex: 'constition',
            key: 'constition',
            width: '50%'

        }


    ];
    return (
        <div >
            <Card >
                <Meta title='Qualification Criteria' description='Define Qualification Criteria' />
                <hr />
                <Table columns={columns} dataSource={data} className='mt-4' />
            </Card>
        </div>
    )
}

export default Criteria