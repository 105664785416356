import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react';
import { DownOutlined } from '@ant-design/icons';

function DropDown({ dropData, text, handleButtonClick }) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            <Menu as="div" className="relative inline-block text-left h-full w-auto">
                <Menu.Button className="w-auto h-full hover:text-slate-600 text-DaDUprimary gap-1 flex justify-center items-center border-0  p-2 hover:!border-[#007BFF]">
                    {text}<DownOutlined className='text-sm ' />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute top-8 left-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {dropData?.map((item) => (
                                <Menu.Item key={item.id}>
                                    {({ active }) => (
                                        <button
                                            type="button"
                                            className={classNames(
                                                active ? 'bg-DaDUprimary text-white' : 'text-gray-700',
                                                'block w-full px-4 py-2 text-left text-sm'
                                            )}
                                            onClick={() => handleButtonClick(item.value)}
                                        >
                                            {item.name}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default DropDown