export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST";
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL";

// PRODUCT DETAILS
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";


// NEW PRODUCT REQUEST
export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS";
export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL";


// DELETE PRODUCT
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";


// UPDATE PRODUCT
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

// BIDDED PRODUCTS
export const BIDDED_PRODUCT_REQUEST = "BIDDED_PRODUCT_REQUEST";
export const BIDDED_PRODUCT_SUCCESS = "BIDDED_PRODUCT_SUCCESS";
export const BIDDED_PRODUCT_FAIL = "BIDDED_PRODUCT_FAIL";

// SELLER PRODUCTS
export const SELLER_PRODUCT_REQUEST = "SELLER_PRODUCT_REQUEST";
export const SELLER_PRODUCT_SUCCESS = "SELLER_PRODUCT_SUCCESS";
export const SELLER_PRODUCT_FAIL = "SELLER_PRODUCT_FAIL";


// TO CLEAR ERROR
export const CLEAR_ERRORS = "CLEAR_ERRORS";



// USING CONSTANT FOR OVERCOME STRING RELATED ERROR