import React from 'react'
import { Card, Input, Form, Alert, Button, Checkbox, Select } from 'antd'


const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

function Applications() {
    return (
        <main className="flex-1 pl-3">
            <div className="w-full min-h-screen p-3 bg-white border">
                <Card title='Register as a Supplier' className='w-full py-2 px-1' >
                    <div className="flex lg:flex-row flex-col ">
                        <div className="lg:w-1/2 w-full pr-3 mr-3 border-r">
                            <div className="flex-1 pb-6 bg-white">

                                <Form
                                    name="basic"
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    color='#008544'
                                >
                                    <Form.Item label="Legal Form of Entity" required>
                                        <Select>
                                            <Select.Option value="demo">Demo</Select.Option>
                                            <Select.Option value="demo">Demo</Select.Option>
                                            <Select.Option value="demo">Demo</Select.Option>
                                            <Select.Option value="demo">Demo</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Country of Registration" required>
                                        <Select>
                                            <Select.Option value="demo">Demo</Select.Option>
                                            <Select.Option value="demo">Demo</Select.Option>
                                            <Select.Option value="demo">Demo</Select.Option>
                                            <Select.Option value="demo">Demo</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Tax Identification Number"
                                        name="taxNo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Tax Identification Number!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <div role="alert" class="px-2 py-1 -mb-4 bg-gray-100 border-t-2 rounded-b text-primary-300 border-primary-300">

                                        <Form.Item
                                            name="remember"
                                            valuePropName="checked"

                                        >
                                            <Checkbox>By continuing using the system you certify that you have read the above service request instruction and accept the applicable Terms and Conditions</Checkbox>
                                        </Form.Item>

                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <div className="flex lg:justify-end justify-start">
                                                <Button type="secondary" htmlType="submit" className='border-1 border-solid border-gray-300 bg-gray-200 hover:bg-transparent'>
                                                    Start Registration
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Form>

                            </div>


                        </div>
                        <div className="lg:w-1/2 w-full">
                            <div className="flex-shrink-0">
                                <div className="p-3 bg-white">
                                    <Alert
                                        message="To access the supplier registration electronic form, please provide your company's tax identification number, country of registration, and legal form of your company."
                                        description={<><p class="my-2 font-semibold ng-star-inserted">Why Register?</p><ul class="list-disc ng-star-inserted"><li> Participate actively in tenders. Submit your bids and request for clarifications </li><li> Become a registered supplier and receive notifications for new tenders </li><li> Receive reminders and notifications for important tender activities </li></ul><p class="my-3 ng-star-inserted"><span class="font-semibold"> Note: </span> To get approval of your company registration, you need to attach scanned copy of signed and stamped official power of attorney letter from the company's general manager. Falsifying documents and/or misrepresent date is a serious offence that can result in criminal charges. </p></>}
                                        type="info"
                                        showIcon
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </main>
    )
}

export default Applications