import React from 'react'
import { Card, Input, Table, Button, Space } from 'antd'
import { PrinterOutlined } from '@ant-design/icons';

const { Search } = Input;


const data = [
    {
        key: '1',
        tin: '0041255271',
        entityName: 'peace security plc',
        legalEntity: 'Private Limited Company',
        action: <PrinterOutlined style={{ cursor: 'pointer' }} />,
        date: "86823"
    },
    {
        key: '2',
        tin: '0087033164',
        entityName: "ABIYOT TESEMA SHIFERAW",
        legalEntity: 'Sole Proprietorship',
        action: <PrinterOutlined style={{ cursor: 'pointer' }} />,
        date: "62712"
    },
    {
        key: '3',
        tin: '0062401805',
        entityName: 'SIFRASH WORKNEH ABITEW',
        legalEntity: 'Sole Proprietorship',
        action: <PrinterOutlined style={{ cursor: 'pointer' }} />,
        date: "61845"
    },
    {
        key: '4',
        tin: '0067948231',
        entityName: 'GOGEB STARS ENGINEERING PLC',
        legalEntity: 'Private Limited Company',
        action: <PrinterOutlined style={{ cursor: 'pointer' }} />,
        date: "51114"
    },
    {
        key: '4',
        tin: '0050513547',
        entityName: 'YONAS ADISSU ORDOFA',
        legalEntity: 'Sole Proprietorship',
        action: <PrinterOutlined style={{ cursor: 'pointer' }} />,
        date: "65511"
    }
];


function Payment() {

    const columns = [

        {
            title: 'Tender Name',
            dataIndex: 'entityName',
            key: 'entityName',
            width: '40%',

        },
        {
            title: 'Reference Num',
            dataIndex: 'tin',
            key: 'tin',
            width: '30%',

        },
        {
            title: 'Amount',
            dataIndex: 'legalEntity',
            key: 'legalEntity',
            width: "10%"
        },
        {
            title: 'Status',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            width: '10%',
            render: (text, record) => (
                <Button onClick={() => printClickConfig()} className='border-DaDUprimary text-DaDUprimary hover:bg-DaDUprimary hover:!text-white'>
                    <Space size="middle">
                        {record.action}
                    </Space>
                </Button>
            ),
        },

    ];
    const printClickConfig = (record) => {
        alert('Print Clicked')
    };
    return (
        <main className="flex-1 pl-3 min-w-[21rem]">
            <div className="w-full min-h-screen p-3 bg-white border">
                <Card title='My Payment' className='w-full py-2 px-1' >
                    <Search placeholder="search here" loading={false} />
                    <Table columns={columns} dataSource={data} className='mt-4 overflow-x-scroll' />
                </Card>
            </div>
        </main>
    )
}

export default Payment