import React, { useState, useRef } from 'react';
import { Card, Input, Button, Space, Table } from 'antd';
import { SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import MetaData from '../MetaData/MetaData';

const data = [
    {
        key: '1',
        name: '0041255271',
        description: 'peace security plc',
        address: 'Private Limited Company',
        date: "Feb 21, 2022",
    },
    {
        key: '2',
        name: '0087033164',
        description: "ABIYOT TESEMA SHIFERAW",
        address: 'Sole Proprietorship',
        date: "Feb 21, 2022",
    },
    {
        key: '3',
        name: '0062401805',
        description: 'SIFRASH WORKNEH ABITEW',
        address: 'Sole Proprietorship',
        date: "Feb 21, 2022",
    },
    {
        key: '4',
        name: '0067948231',
        description: 'GOGEB STARS ENGINEERING PLC',
        address: 'Private Limited Company',
        date: "Feb 21, 2022",
    },
    {
        key: '4',
        name: '0050513547',
        description: 'YONAS ADISSU ORDOFA',
        address: 'Sole Proprietorship',
        date: "Feb 21, 2022",
    },
    {
        key: '4',
        name: '0067948231',
        description: 'GOGEB STARS ENGINEERING PLC',
        address: 'Private Limited Company',
        date: "Feb 21, 2022",
    },
    {
        key: '4',
        name: '0050513547',
        description: 'YONAS ADISSU ORDOFA',
        address: 'Sole Proprietorship',
        date: "Feb 21, 2022",
    },
    {
        key: '3',
        name: '0062401805',
        description: 'SIFRASH WORKNEH ABITEW',
        address: 'Sole Proprietorship',
        date: "Feb 21, 2022",
    },
    {
        key: '2',
        name: '0087033164',
        description: "ABIYOT TESEMA SHIFERAW",
        address: 'Sole Proprietorship',
        date: "Feb 21, 2022",
    },

];

const { Search } = Input;

function Resouces() {

    const history = useHistory()

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '30%',
            ...getColumnSearchProps('description'),
        },
        {
            title: 'Category',
            dataIndex: 'address',
            key: 'address',
            ...getColumnSearchProps('address'),
            sorter: (a, b) => a.address.length - b.address.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            ...getColumnSearchProps('date'),
        },

    ];


    return (
        <div className='bg-DaDUprimary bg-opacity-10 pb-4'>
            <MetaData title="RESOURCE LIST"></MetaData>
            <div className="container mx-auto ">
                <div className=' product_section layout_padding my-0 pt-4'>
                    <div className="section-title" data-aos="fade-up">
                        <h2>Resource List</h2>
                        <p>Check the Resources!</p>
                    </div>
                </div>
                <Card title="Resources List" extra={<Button className='flex items-center border-0 text-red-600'><RollbackOutlined />Back</Button>} style={{
                    width: '100%',
                }}>
                    <Search placeholder="search here" loading={false} />
                    <Table columns={columns} dataSource={data} className='mt-4 overflow-x-scroll' />
                </Card>
            </div>
        </div>
    )
}

export default Resouces