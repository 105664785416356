import React from 'react'
import { Form, Select, Input, Button } from 'antd'
import { FiLock } from 'react-icons/fi'

function TenderPreparation() {
    return (
        <div><div className="flex items-center justify-center">
            <div className="w-1/3 p-3 bg-gray-100 border rounded">
                <div className="flex items-center justify-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="w-20 h-20 m-4">
                        <path fill="#78bf9d" d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"></path>
                        <path fill="#008544" d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"></path>
                        <path fill="#fff" d="M25,17H15c-1.657,0-3,1.343-3,3v7h16v-7C28,18.343,26.657,17,25,17z"></path>
                        <path fill="none" stroke="#fff" d="M20,24L20,24c-2.2,0-4-1.8-4-4v-4c0-2.2,1.8-4,4-4 h0c2.2,0,4,1.8,4,4v4C24,22.2,22.2,24,20,24z"></path>
                    </svg>
                </div>
                <p className="my-4 text-lg font-semibold text-center text-gray-700">Please enter your private key</p>
                <Form>
                    <Form.Item >
                        <Select>
                            <Select.Option value="Technical">Technical Response</Select.Option>
                            <Select.Option value="Finacial">Finacial Response</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <Input placeholder='Enter private key' prefix={<FiLock />} />
                    </Form.Item>
                    <Form.Item
                    >
                        <Button className='py-3 px-3 bg-DaDUprimary text-white rounded flex items-center justify-center w-full !border-2 !border-DaDUprimary hover:bg-transparent hover:!text-DaDUprimary' htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        </div></div>
    )
}

export default TenderPreparation