import React, { useState } from 'react'
import { Menu, Button, Form, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const items = [
    {
        label: 'Request',
        key: 'request',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 25" className="w-4 h-4 mr-1 text-gray-500 fill-current">
            <path d="M15 3C7.82 3 2 7.925 2 14C2 17.368 3.7933281 20.378484 6.6113281 22.396484C6.6832805 23.932468 6.4452784 26.053382 4.3261719 27.03125 A 0.5 0.5 0 0 0 4.3222656 27.033203 A 0.5 0.5 0 0 0 4 27.5 A 0.5 0.5 0 0 0 4.5 28 A 0.5 0.5 0 0 0 4.5429688 27.998047C6.9769949 27.982445 9.0432734 26.667034 10.46875 25.277344C10.92075 24.836344 11.550875 24.619328 12.171875 24.736328C13.081875 24.909328 14.028 25 15 25C22.18 25 28 20.075 28 14C28 7.925 22.18 3 15 3 z M 15 7C17.206 7 19 8.794 19 11C19 12.715 17.929359 13.704 17.068359 14.5C16.406359 15.111 16 15.512 16 16L14 16C14 14.611 14.948937 13.734297 15.710938 13.029297C16.509937 12.291297 17 11.799 17 11C17 9.897 16.103 9 15 9C13.897 9 13 9.897 13 11L11 11C11 8.794 12.794 7 15 7 z M 15 18.5C15.828 18.5 16.5 19.172 16.5 20C16.5 20.828 15.828 21.5 15 21.5C14.172 21.5 13.5 20.828 13.5 20C13.5 19.172 14.172 18.5 15 18.5 z"></path>
        </svg>,
    },
    {
        label: 'Response',
        key: 'response',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 25" className="w-4 h-4 mr-1 text-gray-500 fill-current">
            <path d="M13 0.1875C5.925781 0.1875 0.1875 5.253906 0.1875 11.5C0.1875 14.667969 1.667969 17.527344 4.042969 19.582031C3.523438 22.59375 0.160156 23.8125 0.390625 24.640625C3.230469 25.816406 8.921875 23.230469 10.308594 22.558594C11.179688 22.722656 12.078125 22.8125 13 22.8125C20.074219 22.8125 25.8125 17.75 25.8125 11.5C25.8125 5.25 20.074219 0.1875 13 0.1875 Z M 18.601563 17.488281C18.917969 13.203125 16.828125 12.027344 12.058594 12.027344L12.058594 13.945313C12.058594 14.097656 11.976563 14.230469 11.835938 14.289063C11.785156 14.308594 11.738281 14.316406 11.691406 14.316406C11.59375 14.316406 11.5 14.28125 11.425781 14.207031L6.964844 10.71875C6.820313 10.574219 6.820313 10.335938 6.964844 10.191406L11.429688 6.6875C11.535156 6.582031 11.695313 6.546875 11.835938 6.605469C11.976563 6.664063 12.058594 6.800781 12.058594 6.949219L12.058594 8.988281C16.742188 8.988281 20.621094 10.632813 18.601563 17.488281Z"></path>
        </svg>,
    },

];

const props = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

function Clarification() {
    const [current, setCurrent] = useState('request');
    const [newView, setNewView] = useState(false);
    const [data, setData] = useState('Sample, text')

    const handleChange = (value) => {
        setData(value)
    }

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    return (
        <main className="-m-2 ">
            <div className="flex-shrink-0 ">
                <div className="flex items-center justify-between px-3 py-4 text-sm font-semibold text-gray-900 bg-white border-b">
                    <div > Clarification </div>
                </div>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            </div>
            <div className='px-4'>
                {!newView &&
                    <>{
                        current === "request" ?
                            <>
                                <div className="flex-1 overflow-y-auto bg-white ng-star-inserted">
                                    <div className="flex items-center justify-end my-2 mr-2">
                                        <Button className="py-2 px-4 flex items-center bg-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary"
                                            onClick={() => setNewView(true)}
                                        >
                                            <i className="anticon anticon-plus">
                                                <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="plus" aria-hidden="true">
                                                    <defs>
                                                        <style></style>
                                                    </defs>
                                                    <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                                                    <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                                                </svg>
                                            </i>
                                            <span className="ng-star-inserted">New </span>
                                        </Button>
                                    </div>
                                </div>

                                <div className="flex flex-col h-full items-center justify-center py-10">
                                    <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
                                        <i className="anticon text-4xl text-gray-600 ">
                                            <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                                                <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                                                </path>
                                            </svg>
                                        </i>
                                    </div>
                                    <div className="mt-6 text-center">
                                        <p className="text-lg font-semibold mb-0">
                                            Clarification
                                        </p>
                                        <p >
                                            No clarification request
                                        </p>
                                    </div>
                                </div>
                            </>
                            : <div className="flex flex-col h-full items-center justify-center py-10">
                                <div className="flex items-center justify-center w-24 h-24 rounded-full bg-DaDUprimary bg-opacity-20 ">
                                    <i className="anticon text-4xl text-gray-600 ">
                                        <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="container" aria-hidden="true">
                                            <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z">
                                            </path>
                                        </svg>
                                    </i>
                                </div>
                                <div className="mt-6 text-center">
                                    <p className="text-lg font-semibold mb-0">
                                        Clarification
                                    </p>
                                    <p >
                                        No clarification request
                                    </p>
                                </div>
                            </div>
                    }</>
                }
                {newView &&
                    <Form
                        layout="vertical"
                        className='mt-4'
                    >
                        <Form.Item
                            label="Subject"
                            name="Subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <ReactQuill value={data} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item
                            label="Reference on the tender document"
                            name="referenceDocument"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Expected outcome"
                            name="Expectedoutcome"
                        >
                            <ReactQuill value={data} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item>
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Add File</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button className='py-2 px-4 bg-DaDUprimary border-2 border-DaDUprimary text-white hover:bg-transparent hover:!text-DaDUprimary flex items-center font-bold'
                                onClick={() => setNewView(false)}
                            >Save</Button>
                        </Form.Item>
                    </Form>
                }
            </div>
        </main>
    )
}

export default Clarification