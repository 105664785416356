import React, { useState } from 'react'
import { Card, Button, Table, Collapse, Form, Select, Divider, } from 'antd'
import { FaPlus, FaSave } from 'react-icons/fa'

const dataSource = [
    {
        key: '1',
        lotName: 'sample lot name',
        procuringEntity: 'sample procuring entity',
        guaranteeType: 'sample guaranty type',
        status: "sample status"
    },
    {
        key: '2',
        lotName: 'sample lot name',
        procuringEntity: 'sample procuring entity',
        guaranteeType: 'sample guaranty type',
        status: "sample status"
    },
];

const columns = [
    {
        title: 'Lot Name',
        dataIndex: 'lotName',
        key: 'lotName',
    },
    {
        title: 'Procuring Entity',
        dataIndex: 'procuringEntity',
        key: 'procuring Entity',
    },
    {
        title: 'Guarantee Type',
        dataIndex: 'guaranteeType',
        key: 'guaranteeType',
    },
    {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
    }
];

function BidSecurity() {
    const [viewForm, setViewForm] = useState(false)
    return (
        <div>
            {!viewForm ?
                <Card
                    title="Guaratee list"
                    extra={<Button onClick={() => setViewForm(true)} className='flex items-center justify-center border-2 border-DaDUprimary bg-DaDUprimary text-white hover:!border-DaDUprimary hover:bg-transparent hover:!text-DaDUprimary' icon={<FaPlus />}>place new guarantee</Button>}
                    style={{
                        width: '100%',
                    }}
                >
                    <Table dataSource={dataSource} columns={columns} />
                </Card>
                :
                <Card
                    title="Lot info"

                    style={{
                        width: '100%',
                    }}
                >
                    <div className="-m-4 px-3">
                        <div className="w-full">
                            <tr className="flex border-t border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Lot Name</td>
                                <td className="p-2">Procurement of Supply, Installation and Commissioning of Networking Infrastructure and Modular Data Center </td>
                            </tr>
                            <tr className="flex border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Organization Name</td>
                                <td className="p-2">Woldiya University</td>
                            </tr>
                            <tr className="flex border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Reference No</td>
                                <td className="p-2"></td>
                            </tr>
                            <tr className="flex border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Minimum Validity Date</td>
                                <td className="p-2">150</td>
                            </tr>
                            <tr className="flex border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Submission Deadline</td>
                                <td className="p-2">Apr 4, 2024</td>
                            </tr>
                            <tr className="flex border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Security Amount</td>
                                <td className="p-2">500000ETB</td>
                            </tr>
                            <tr className="flex border-b border-dashed">
                                <td className="w-1/3 p-2 font-semibold bg-gray-100 border-r">Security Form</td>
                                <td className="p-2">Bank_Guarantee</td>
                            </tr>
                        </div>
                    </div>
                    <Collapse
                        className='mt-8'
                        items={[
                            {
                                key: '1',
                                label: 'Terms and Conditions',
                                children: <p>Terms and Conditions</p>,
                            },
                        ]}
                    />
                    <div className="flex mt-3">
                        <div className="w-full md:w-1/2 md:mr-1">
                            <Form layout='vertical'>
                                <Form.Item
                                    label='Guarantor Organization'
                                    name='guarantorOrganization'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input!',
                                        },
                                    ]}
                                >
                                    <Select placeholder='Choose'>
                                        <Select.Option>Option 1</Select.Option>
                                        <Select.Option>Option 2</Select.Option>
                                        <Select.Option>Option 3</Select.Option>
                                        <Select.Option>Option 4</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="w-full md:w-1/2 md:mr-1">
                            <Form layout='vertical'>
                                <Form.Item
                                    label='Guarantor Organization'
                                    name='guarantorOrganization'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input!',
                                        },
                                    ]}
                                >
                                    <Select placeholder='Choose'>
                                        <Select.Option>Option 1</Select.Option>
                                        <Select.Option>Option 2</Select.Option>
                                        <Select.Option>Option 3</Select.Option>
                                        <Select.Option>Option 4</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <Divider />
                    <Button onClick={() => setViewForm(false)} className='flex items-center justify-center border-2 border-DaDUprimary bg-DaDUprimary text-white hover:!border-DaDUprimary hover:bg-transparent hover:!text-DaDUprimary' icon={<FaSave />}>Save</Button>
                </Card>
            }
        </div>
    )
}

export default BidSecurity