import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { FaBeer } from 'react-icons/fa';
import { MdSecurity, MdEventAvailable } from 'react-icons/md';

import Img from "../images/values-1.png";
import Img4 from "../images/arrow-310633_960_720.png";
import BidImg from "../images/values-3.png";
import Auction1 from "../images/auction1.png";

import "./homestyle.scss";
import MetaData from '../MetaData/MetaData';
import { getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from '../Loader/Loader';
import { useAlert } from 'react-alert';


const Home = () => {
  const alert = useAlert();
  const history = useHistory()
  const dispatch = useDispatch();
  const { loading, error, products, productCount } = useSelector(
    (state) => state.products
  );

  useEffect(() => {


    if (error) {
      return alert.error(error);
    }
    dispatch(getProduct());

  }, [dispatch, error, alert]);

  return (
    <>{loading ? (<Loader />) : (
      <div className='bg-DaDUprimary bg-opacity-10 '>
        <MetaData title="BEST BID"></MetaData>
        <section className='d-flex align-items-center homepg w-full h-[80vh]'>

          <div className="container-fluid nav_bg  lg:mt-0 md:mt-[24rem] mt-[14rem]">
            <div className='row'>
              <div className='col-10 mx-auto'>
                <div className='row'>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column' data-aos="fade-up" data-aos-delay="300">
                    <h1> <strong className='text-DaDUprimary'><br />DaDU Proc</strong></h1>

                    <h2 className="my-3">
                      DaDU Proc is a service in which procurement users or participants bid for products or services via the Internet.
                    </h2>
                    <div className='mt-3'>
                      <NavLink to="/signup" className='border-2 border-DaDUprimary rounded-full text-DaDUprimary no-underline py-2 px-4 hover:bg-DaDUprimary hover:text-white'>
                        Get Started
                      </NavLink>
                    </div>
                  </div>
                  <div className='col-lg-6 order-1 order-lg-2 header-img flex justify-center' data-aos="fade-up" data-aos-delay="400">
                    <img src={Auction1} className='img-fluid animated hedrimg' alt="auction img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Home Section 2 */}
        <div className="container-fluid nav_bg sectiontwocls lg:mt-8 md:mt-[25rem] mt-[15rem]">
          <div className='row'>
            <div className='col-10 mx-auto'>
              <section className="section">
                <div className="container">
                  <div className="row justify-content-center text-center mb-5">
                    <div className="col-md-5" data-aos="fade-up">
                      <h2 className="section-heading">A user-friendly website</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                      <div className="feature-1 text-center">
                        <div className="bg-DaDUgradient text-white text-5xl mt-0 mx-auto h-[100px] w-[100px] rounded-[50%] relative mb-[30px] inline-flex items-center justify-center shadow-DaDUshadow">
                          <div className='hmicn'>
                            <MdSecurity />
                          </div>
                        </div>
                        <h3 className="mb-3">Security</h3>
                        <p className='!text-gray-500'>All the sellers are to be verified through our system. Your account details are secures. User's password is to be encrypted .You can bid safely without any 3rd party interruption.</p>
                      </div>
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
                      <div className="feature-1 text-center">
                        <div className="bg-DaDUgradient text-white text-5xl mt-0 mx-auto h-[100px] w-[100px] rounded-[50%] relative mb-[30px] inline-flex items-center justify-center shadow-DaDUshadow">
                          <div className='hmicn'>
                            <MdEventAvailable />
                          </div>
                        </div>
                        <h3 className="mb-3">Availability</h3>
                        <p className='!text-gray-500'>The web-app can available 24*7. User can login and register at website at
                          any time. Lots of the product will be available only for the decided time by
                          seller.</p>
                      </div>
                    </div>

                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500">
                      <div className="feature-1 text-center">
                        <div className="bg-DaDUgradient text-white text-5xl mt-0 mx-auto h-[100px] w-[100px] rounded-[50%] relative mb-[30px] inline-flex items-center justify-center shadow-DaDUshadow">
                          <div className='hmicn'>
                            <FaBeer />
                          </div>
                        </div>
                        <h3 className="mb-3">Flexibility</h3>
                        <p className='!text-gray-500'>The supplier can update/add/delete the product data instantly.
                          Easy steps to sell your product via bidding.User can easily update their profile. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section secthreecls">
                <div className="container">
                  <div className="row justify-content-center text-center mb-5 mt-2" data-aos="zoom-in" data-aos-delay="200">
                    <div className="col-md-6 mb-5 flwpthcls">
                      <img src={Img4} alt="Auction Image" className="img-fluid" />
                      <h1> Follow This Path </h1>
                      {/* <strong className='brand-name'><br/>Follow This Path</strong> */}
                      {/* <strong className='brand-name'><br/> Follow This Path</strong> */}

                    </div>
                  </div>

                  {/* 
        <div className="row justify-content-center text-center mb-5 homeimgcls" data-aos="zoom-in" data-aos-delay="200">
          
          <div className="col-md-6 mb-5">
            <img src={Img} alt="Auction Image" className="img-fluid"/>
          </div>
        </div> */}

                  <div className="row md:space-y-0 space-y-4">
                    <div className="col-md-4 ">
                      <div className="step rounded-lg " data-aos="fade-up" data-aos-delay="300">
                        <span className="text-DaDUprimary font-bold mb-2 block">01</span>
                        <h3>Create Profile</h3>
                        <p>Sign up in our website, and make your user Profile. </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="step rounded-lg" data-aos="fade-up" data-aos-delay="400">
                        <span className="text-DaDUprimary font-bold mb-2 block">02</span>
                        <h3>Sign In</h3>
                        <p>Provide your correct user Id and Password and Login</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="step rounded-lg" data-aos="fade-up" data-aos-delay="500">
                        <span className="text-DaDUprimary font-bold mb-2 block">03</span>
                        <h3>Enjoy the app</h3>
                        <p>Sell and Buy Products with Best Bid</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section secfourcls">
                <div className="container">
                  <div className="row align-items-center crtlot">
                    <div className="col-md-4 me-auto" data-aos="fade-up" data-aos-delay="400">
                      <h2 className="mb-4">Create a lot</h2>
                      <p className="mb-4">All the valid information related to item for bidding
                        i.e. lot name, description of the item, category of item, date of bidding,
                        minimum price, end time of bidding, payment method, images of product</p>
                      <p>
                        <button onClick={() => history.push('/about')} className="border-2 border-DaDUprimary no-underline text-white bg-DaDUprimary py-1 px-3 w-auto rounded hover:!bg-transparent hover:!text-DaDUprimary">Get Started</button></p>
                    </div>
                    <div className="col-md-6" data-aos="zoom-in" data-aos-delay="500">
                      <img src={Img} alt="Auction" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section mb-10">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-4 ms-auto order-2" data-aos="fade-up" data-aos-delay="400">
                      <h2 className="mb-4">Make a bid</h2>
                      <p className="mb-4">Customer will see the product detail and if interested in product then
                        he/she will make a bid of that product, valid identity proof</p>
                      <p>
                        <button onClick={() => history.push('/lot')} className="border-2 border-DaDUprimary no-underline text-white bg-DaDUprimary py-1 px-3 w-auto rounded hover:!bg-transparent hover:!text-DaDUprimary">Get Started</button></p>
                    </div>
                    <div className="col-md-6" data-aos="zoom-in" data-aos-delay="500">
                      <img src={BidImg} alt="Auction" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )}</>

  );
};

export default Home;
